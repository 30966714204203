import Layout from "../components/layout"
import Hero from "../components/hero"
import React, { Component } from "react"
import Reviews from "../components/reviews"
import { useStaticQuery, graphql } from "gatsby"
import SEO from "../components/seo"

const AboutPage = () => {
  const data = useStaticQuery(graphql`
    query AboutQuery {
      paintBrushImage: cloudinaryMedia(
        public_id: { eq: "pivot-painters-chicago/brush-painting-minified-sizereduce" }
      ) {
        secure_url
      }
      mannyImage: cloudinaryMedia(public_id: { eq: "pivot-painters-chicago/mannymunoz" }) {
        secure_url
      }
    }
  `)
  return (
    <Layout>
      <SEO title="About Us" description="About Pivot Painters Chicago IL" />
      <Hero name="About" src={data.paintBrushImage.secure_url} />
      <div className="aboutUs">
        <h2>Our Story</h2>
        <div className="container">
          <div className="aboutPhotoContainer">
            <img
              src={data.mannyImage.secure_url}
              alt="Pivot Painters - Photo of Manny Munoz the owner of Pivot Painters"
            />
            <p>Manny Munoz | Owner Of Pivot Painters</p>
          </div>
          <p>
            <span className="highlight heavyLato">Pivot Painters</span> is a
            leader in the residential painting industry in Chicago. We
            are based in Logan Square and are family owned and operated.<br/> Manny's grandfather
            would make him paint in the summers, which he did'nt like doing but he learned to love the trade and start a business from it. We
            are insured, and put most of our energy towards a great customer
            experience and providing top notch painting craftsmanship.<br/> We do so
            with leading painting techniques and premium paint and tools to make sure
            you get the best professional painting finish!
            <br></br>
            <br></br>
            We are happy to answer any other questions you might have at{" "}
            <a href="tel:3128549180">‪(312) 854-9180‬</a>
          </p>
        </div>
        <Reviews></Reviews>
      </div>
    </Layout>
  )
}

export default AboutPage
